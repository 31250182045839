.arrows {
  position: absolute;
  right: 100px;
  bottom: 10px;
  background-color: transparent;
  z-index: 10;
  border-radius: 5px;
}

.arrow {
  cursor: pointer;
  opacity: .8
}

.arrow:hover {
  opacity: 1;
}

.arrow.up {
  grid-row: 1;
  grid-column: 2;
}

.arrow.animated {
  animation: test 1s infinite alternate;
}

.arrow.left {
  grid-row: 2;
  grid-column: 1;
}

.arrow.right {
  grid-row: 2;
  grid-column: 3;
}

.arrow.down {
  grid-row: 2;
  grid-column: 2;
  /* width: 80px;
  height: 80px;
  background: #999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; */
}

.arrow.down i {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
       -ms-user-select: none; /* Internet Explorer/Edge */
           user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome, Opera and Firefox */
}

@keyframes test {
  from {opacity: 1}
  to {opacity: 0.5}
}
