.icons {
    position: absolute;
    /* width: 1344px; */
    /* height: 756px; */
    top: 0;
    /* left: 0; */
    width: 100%;
    height: 100%;
}

.evt-icon {
  position: absolute;
  z-index: 2;
  transform-origin: center center;
}

.canvas-container {
  z-index: 5;
}

.board-evt {
  position: absolute;
  top: 0;
  width: 1344px;
  height: 756px;
  background: #000;
  z-index: 2000;
  display: flex;
  align-items: center;
}
/* float */
.__se__float-left {
    float: left;
}
.__se__float-right {
    float: right;
}
.__se__float-center {
    float: center;
    text-align: center;

}
.__se__float-none {
    float: none;
}
.board-evt-content.card {
  width: 800px;
  margin: auto;
}

.board-evt-title {
  padding: 8px 20px;
  border-bottom: 1px solid #bdbdbd;
}

.board-evt-body {
  padding: 20px;
  height: 420px;
  overflow: auto;
}

.board-evt-footer {
  text-align: center;
  padding: 20px;
}

.event-label {
  z-index: 5000;
  position: absolute;
  color: #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 2px;
  pointer-events: none;
}
