.problem-report {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  z-index: 50;
  background: currentColor;
  padding: 5px;
}

.problem-report textarea {
  min-width: 400px;
  height: 5pc;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  padding: 10px;
  margin-right: 10px;
  color: #fff;
}


@media screen and (max-height: 750px) {
  .problem-report {
    top: 2px;
  }

  .problem-report textarea {
    height: 3pc;
  }
}
