.inner-wrapper {
  width: 1344px;
  height: 756px;
  position: relative;
  display: block;
  margin: 0 auto;
}

#forwards, #backwards {
  position: absolute;
  width: 1344px;
}

.video {
  outline: none;
};
