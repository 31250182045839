#store {
  /* // background: #000; */
  overflow: hidden;
}

.store-wrapper {
  /* display: flex;
  width: 72vw;
  height: 100vh;
  margin: auto; */
}

.spinner-wrapper {
  text-align: center;
}

@media only screen and (max-width: 1919px) {
  .store-wrapper {
    /* width: 100vw; */
  }

  .video-wrapper {
    /* width: 100%; */
  }
}
